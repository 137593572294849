<template>
  <v-container class="my-0 my-md-16 py-0  px-0 px-md-3 pb-md-16">
    <v-row class="no-gutters">
      <v-col
        xl="6"
        offset-xl="3"
        md="6"
        offset-md="3"
        sm="12"
        offset-sm="0"
        class="py-0"
      >
        <v-card
          :class="
            $vuetify.breakpoint.smAndDown ? 'elevation-0' : 'elevation-24'
          "
          style="border-radius:6px"
        >
          <v-toolbar
            flat
            color="primary"
            height="120px"
            dark
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'rounded-t-0'
                : 'rounded-t-lg pl-3'
            "
          >
            <v-toolbar-title class="d-flex align-center">
              <v-icon large>
                mdi-qrcode
              </v-icon>
              <span class="pl-6 text-h4">{{ $t("qrCode") }}</span>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text
            class="pa-6"
            :class="$vuetify.breakpoint.smAndDown ? 'mb-16 pb-16 ' : ''"
          >
            <v-select
              outlined
              rounded
              v-model="miktarDur"
              :label="$t('adet')"
              hide-details=""
              class="py-6"
              :items="[1, 5, 10, 25, 50, 100]"
            />

            <div class="lale" v-show="false">
              <div v-for="(code, i) in miktar">
                <vue-qrcode
                  :quality="1"
                  :scale="10"
                  :value="`${profil.website}?uID=${uuidCode()}`"
                  ref="qrcode"
                />
              </div>
            </div>
          </v-card-text>
          <div :class="$vuetify.breakpoint.smAndDown ? 'fixedCardActions' : ''">
            <v-divider />
            <v-card-actions
              class=" pa-0"
              :class="$vuetify.breakpoint.smAndDown ? 'white' : 'primary'"
            >
              <v-spacer />
              <v-btn
                block
                large
                :loading="loading"
                :color="$vuetify.breakpoint.smAndDown ? 'primary' : 'white'"
                text
                class="font-weight-bold text-capitalize"
                @click="download()"
              >
                {{ $t("indir") }}
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { veri, depo } from "./../db";
import VueQrcode from "vue-qrcode";
import { uuid } from "vue-uuid";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export default {
  name: "Qr",
  components: {
    VueQrcode
  },
  data: () => ({
    olustur: false,
    loading: false,
    miktar: 1,
    miktarDur: 1,
    profil: {
      website: ""
    }
  }),
  firebase: {
    profil: veri.ref("profil")
  },
  methods: {
    uuidCode: function() {
      return uuid.v4();
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    download() {
      var zip = new JSZip();
      this.loading = true;
      this.miktar = this.miktarDur;
      setTimeout(() => {
        this.loading = false;
        for (var i = 0; i < this.$refs.qrcode.length; i++) {
          var file = this.dataURLtoFile(this.$refs.qrcode[i].dataUrl, "a.png");
          zip.file("smile" + i + ".png", file, { base64: true });
        }
        zip.generateAsync({ type: "blob" }).then(function(content) {
          // see FileSaver.js
          saveAs(content, "example.zip");
        });
      }, 100 * this.miktarDur);
    }
  },
  created() {}
};
</script>
<style lang="scss"></style>
